// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alcohol-abv-calculator-js": () => import("./../../../src/pages/alcohol/abv-calculator.js" /* webpackChunkName: "component---src-pages-alcohol-abv-calculator-js" */),
  "component---src-pages-dummy-lorem-ipsum-generator-js": () => import("./../../../src/pages/dummy/lorem-ipsum-generator.js" /* webpackChunkName: "component---src-pages-dummy-lorem-ipsum-generator-js" */),
  "component---src-pages-gambling-odds-converter-js": () => import("./../../../src/pages/gambling/odds-converter.js" /* webpackChunkName: "component---src-pages-gambling-odds-converter-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-math-percent-error-calculator-js": () => import("./../../../src/pages/math/percent-error-calculator.js" /* webpackChunkName: "component---src-pages-math-percent-error-calculator-js" */),
  "component---src-pages-math-percentage-calculator-js": () => import("./../../../src/pages/math/percentage-calculator.js" /* webpackChunkName: "component---src-pages-math-percentage-calculator-js" */),
  "component---src-pages-random-memorable-password-generator-js": () => import("./../../../src/pages/random/memorable-password-generator.js" /* webpackChunkName: "component---src-pages-random-memorable-password-generator-js" */),
  "component---src-pages-random-number-generator-js": () => import("./../../../src/pages/random/number-generator.js" /* webpackChunkName: "component---src-pages-random-number-generator-js" */),
  "component---src-pages-random-password-generator-js": () => import("./../../../src/pages/random/password-generator.js" /* webpackChunkName: "component---src-pages-random-password-generator-js" */),
  "component---src-pages-random-question-generator-js": () => import("./../../../src/pages/random/question-generator.js" /* webpackChunkName: "component---src-pages-random-question-generator-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-text-word-counter-js": () => import("./../../../src/pages/text/word-counter.js" /* webpackChunkName: "component---src-pages-text-word-counter-js" */)
}

